import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Borderline = () => {
  return (
    <Layout>
      <Head title="borderlinegrafix" />
      <h3>borderlinegrafix</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Texas
      </h4>
      <p>
        <OutboundLink href="https://ello.co/borderlinegrafix">
          Ello
        </OutboundLink>
      </p>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://assets1.ello.co/uploads/asset/attachment/13554754/ello-optimized-43ce6373.jpg"
          alt="borderlinegrafix art"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://assets1.ello.co/uploads/asset/attachment/12888813/ello-optimized-b0e5a9f6.jpg"
          alt="borderlinegrafix art"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://assets2.ello.co/uploads/asset/attachment/15356482/ello-optimized-aa734b96.jpg"
          alt="borderlinegrafix art"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://assets0.ello.co/uploads/asset/attachment/13854322/ello-optimized-845cee95.jpg"
          alt="borderlinegrafix art"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://assets1.ello.co/uploads/asset/attachment/13892312/ello-optimized-87512212.jpg"
          alt="borderlinegrafix art"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src="https://assets1.ello.co/uploads/asset/attachment/14230254/ello-optimized-4ea9c10f.jpg"
          alt="borderlinegrafix art"
        />
      </div>
    </Layout>
  );
};

export default Borderline;
